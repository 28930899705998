import { type } from '@testing-library/user-event/dist/type';
import React, {Component} from 'react';
import './style.css';



export default class HeaderComponent extends Component{


  
  
  constructor(props) {
		super(props);

		this.state = {
			items: [],
			DataisLoaded: false
		};
	}

	// ComponentDidMount is used to
	// execute the code
	componentDidMount() {

		const queryParameters = new URLSearchParams(window.location.search)
		const billnumber = queryParameters.get("billnumber")
  
   const obj = {
      "FromId": 3,
      "DocNo": billnumber
    };

  // Simple POST request with a JSON body using fetch
  const requestOptions = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' }
  };

  const url="https://yenerp.com/fastapi/invoices/"+billnumber;

		fetch(
      url, requestOptions)
			.then((res) => res.json())
			.then((json) => {
				this.setState({
					items: json,
					DataisLoaded: true,
          count1: json.count
				});
			})
	}
  render(){

		const { DataisLoaded, items,count1 ,ty,url2,url} = this.state;


		//var total = items.testdata[0].TotAmount;

		if (!DataisLoaded) return 
		<div>

    </div> ;

		return (
  
<div className = "App">
<header>
<table><tr><td> <img src="bm.jpg"  width="250" height="100"/>
</td>
<td></td><td></td><td></td><td></td><td></td><td></td>
<td>Head Office : 
<br/>
<br/>
Best Mummy Sweets & Cakes
<br/>
<br/>
No 72 , Salai Bazar, Ramnad - 623501
<br/>
<br/>
Ph : 8610863485
<br/>
<br/>
GST - 33AATFB12B1ZW
</td></tr></table>
                 

                       <h1> SALES INVOICE </h1>        
             <table className="table table-bordered">
               <tr><td></td>

                <td>

               <font size = "7">Best Mummy Sweets & Cakes</font>
<br/><br/>
               Branch :{items.branch} <br/><br/> 
              
               {/* {items.testdata[0].City} <br/><br/> */}


                Ph:8610863485 <br/><br/>
               {/* FASSI :  {items.testdata[0].FssiNo}<br/><br/> */}
             
                 </td>
                 <td>
                 
                  Bill No &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  : &nbsp;{items.invoiceNo} 
                 <br/>
                 <br/>
                 Date &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                 : &nbsp;{items.date} 
                 <br/>   
                 <br/>     
                 Time &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                 :&nbsp; {items.time} 
                 <br/>   
                 <br/>     
                 Customer Number &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:&nbsp; {items.phoneNumber}
                 <br/>   
                 <br/>     
                 Sales Person &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:&nbsp; {items.employeeName}
                 <br/>    </td>
                 </tr>
             </table>
                               
              </header>
             
      
		</div>
	);
}
}

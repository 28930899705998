import React, {Component} from 'react';
import HeaderComponent from './headerComponent';
import ArticleComponent from './articleComponent';
import './style.css';

export default class InvoiceComponent extends Component{
  render(){
    return(
      <form>
        <HeaderComponent />
       <ArticleComponent /> 

      </form>
      
    )
  }
}
import React, {Component} from 'react';
import './style.css';

var flag=false;

export default class HeaderComponent extends Component{

  constructor(props) {
		
	   
	 super(props);
	this.state = {
			items: [],
			DataisLoaded: false
		};
	}

	// ComponentDidMount is used to
	// execute the code
	componentDidMount() {

		const queryParameters = new URLSearchParams(window.location.search)
		const billnumber = queryParameters.get("billnumber")
	  
	  

    
    const obj = {
		"FromId": 3,
		"DocNo": billnumber
	  };

	function ListItem(props) {
		// Correct! There is no need to specify the key here:
		return <li>{props.value}</li>;
	  }
	  
	  function NumberList(props) {
		const numbers = props.numbers;
		const listItems = numbers.map((number) =>
		  // Correct! Key should be specified inside the array.
		  <ListItem key={number.toString()}
					value={number} />
		);
		return (
		  <ul>
			{listItems}
		  </ul>
		);
	  }
    

  // Simple POST request with a JSON body using fetch
  const requestOptions = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
      
  };

const url="https://yenerp.com/fastapi/invoices/"+billnumber;
console.log("5..");
		fetch(
      url, requestOptions)
			.then((res) => res.json())
			.then((json) => {
				console.log(json); // Print the response
				this.setState({
					items: json,
					DataisLoaded: true,
					count1: json
				});
			})
	}


	render() {

		flag=false;

		const { DataisLoaded, items,count1 ,type} = this.state;
        			
		const taxcodes=[{
            "t1":null ,
            "t2":null} ]; 
		
		const result1=[]; 
		var tax3=[];

		var arr3={taxcode:'',taxamount:''}

		
		var taxval=0;

     	const taxamounts=[]; 
		const taxamounts1=["fff"]; 
	    console.log(items.testdata);
	
		const taxarray = [];
			
		console.log("items.testdata");
		console.log(items.testdata);
        





//var i=0;

var arr3=[];


arr3=items.tax;




// Assuming items is an array of objects with properties itemName, tax, and amount
// And tax is an array of objects with properties tax and total

 let tax2 = [];
 let amount2 = [];

 const itemName = items.itemName

 tax2 = items.tax
 amount2 = items.amount

 //const length2=itemName[0].length;

 





const arr = [1, 2, 3, 4, 5, 6];





		//var total = items.testdata[0].TotAmount;

		if (!DataisLoaded) return 
		

		return (

		<div className = "App">
			
			


    {

            
		<table className="table table-bordered"> 
		<tr>
        <th>Item</th>
        <th>Qty</th>
        <th>Price</th>
        <th>Tax%</th>
        <th>Amount</th>
       </tr>
	   {
	   
    (() => {
        const rows = [];


		for (let i = 0; i < itemName.length; i++) {

			let result = tax2[i].substring(0, tax2[i].indexOf('%'));

			let taxVal = parseInt(result);
			let taxVal1 = taxVal / 100;
			let amount = parseInt(amount2[i]);
			let tax1 = 0;
			let f = 0;
		
			if (i === 0 && result !== "0") {
				tax1 = (amount - amount / (1 + taxVal1)) / 2 * 100 / 100;
				tax3.push({tax: parseInt(result), total: amount, taxAmount: tax1});
			} else {
				if (result !== "0") {
					for (let j = 0; j < tax3.length; j++) {
						if (parseInt(result) === tax3[j]["tax"]) {
							f = 1;
							tax3[j]["total"] += amount;
							tax3[j]["taxAmount"] += ((amount - amount / (1 + taxVal1)) / 2 * 100 / 100);
						}
					}
				}
		
				if (f === 0 && result !== "0") {
					tax1 = (amount - amount / (1 + taxVal1)) / 2 * 100 / 100;
					tax3.push({tax: parseInt(result), total: amount, taxAmount: tax1});
				}
			}
          
        }


		

		

        for (let i = 0; i < itemName.length; i++) {
			
            const item = itemName[i];
			const qty = items.qty[i];
			const price = items.price[i];
			const tax = items.tax[i];
			const amount = items.amount[i];
			const taxtPercentage = tax3.length;
			

			let result = tax.substring(0, tax.indexOf('%'));

			

            // Push JSX for each row into the rows array
            rows.push(
                <tr key={i}>
                    <td>{item}</td>
                     <td>{qty} </td>
                    <td>{price}</td>
                    <td>{tax}</td>
                    <td>{amount}</td> 
                </tr>
            );
        }

		


        // Return the array of JSX rows
        return rows;
    })()
}


		

		<tr>
		<th>
		</th>
        <th></th>
        <th></th>
        <th>Total</th>
        <th>{items.totalAmount}</th></tr>

		{items.discountPercentage!="" && items.discountPercentage!="0" ? (
			flag=true,
    <tr>
        <td></td>
        <td>Discount%</td>
        <td>{items.discountPercentage}</td>
        <td>Discount Amount</td>
        <td>{items.discountAmount}</td>
    </tr>
) : null}




{items.customCharge!="" && items.customCharge!="0" ? (
	flag=true,
	
	    <tr>
        <td></td>
		<td></td>
		<td></td>
        <td>Custom Charge</td>
        <td>{items.customCharge}</td>
    </tr>
) : null}




{flag==true ?(
    <tr><th></th>
	<th></th>
	<th></th>
	<th>Final Total {}</th>
	<th>{items.totalAmount2}</th></tr>
) : null} 





</table>
	}
{tax3.map((taxItem, index) => (
    <div key={index}>
        <p>{`State GST @ ${taxItem.tax / 2}% : ${taxItem.taxAmount.toFixed(2)}  Central GST @ ${taxItem.tax / 2}% : ${taxItem.taxAmount.toFixed(2)}`}</p>
    </div>
))}



		

			

<br/>			
{/* <br/><br/><br/>			
<b>
{tax5 ? "\n" + 'SGST@2.5% '+": " + (Math.round((Math.round(tax5)-Math.round(tax5)/(1+0.05))/2 * 100) / 100
) +" "+" "+ 'CGST@2.5% '+": " +(Math.round((Math.round(tax5)-Math.round(tax5)/(1+0.05))/2 * 100) / 100
) +"\n" : ''} </b> <br/>


<b>{tax12 ? 'SGST@6% '+": " + (Math.round((Math.round(tax12)-Math.round(tax12)/(1+0.12))/2 * 100) / 100
) +" "+" "+ 'CGST@6% '+": " +(Math.round((Math.round(tax12)-Math.round(tax12)/(1+0.12))/2 * 100) / 100
) : ''}</b> <br/>



<b>{tax18 ? 'SGST@9% '+": " + (Math.round((Math.round(tax18)-Math.round(tax18)/(1+0.18))/2 * 100) / 100
) +" "+" "+ 'CGST@9% '+": " +(Math.round((Math.round(tax18)-Math.round(tax18)/(1+0.18))/2 * 100) / 100
) : ''}</b> <br/>








<br/>
	


			
			<br/><br/><br/> */}
			

			<font size="5"></font>
      <center>Thank You. Please Visit Again !...</center><br/>

	  <font/>
		</div>
	);
}
}
